.hero {
    width: 100%;
    height: 100vh;
    position: relative;
}

.hero-mid {
    width: 100%;
    height: 70vh;
    position: relative;
}

.hero-mid h1 {
    padding-top: 4rem !important;
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 50%;
}

.hero2-text {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.hero2-text h1 {
    font-size: 3rem;
    font-weight: 1000;
    background: #111;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.hero2-text h2 {
    font-size: 2rem;
    font-weight: 1000;
    background: #111;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.textInfo {
    padding: 0.5rem 0 2rem 0;
}

.hero2-text h4 {
    font-size: 1rem;
    font-weight: 800;
    color: #111;
}

.hero2-text p {
    font-size: 1.5rem;
    font-weight: 800;
    color: #111;
}

.hero2-text .show {
    text-decoration: none;
    background: #fff;
    padding: 1rem 2rem;
    border-radius: 6px;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 2px;
    color: #222;
}

.hero2-text .hide {
    display: none;
}

@media screen and (max-width: 555px) {
    .hero2-text h1 {
        padding: 10px 20px;
        font-size: 3rem;
    }

    .hero2-text p {
        font-size: 1.1rem;
        padding: 0 0 2rem 0;
    }

    .hero2-text .show {
        padding: 0.6rem 1.1rem;
        border-radius: 6px;
        font-size: 1rem;
    }
}