@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

.App {
    font-family: sans-serif;
    text-align: center;
}

.flexbox-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10px;
}

@media screen and (max-width: 850px) {
    .flexbox-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px;
    }
}

.details {
    display: "flex";
    align-items: "center";
    height: "100%";
    padding: 20px;
}

.scheduleTimes {
    padding: 20px;
}

.prizeGrid {
    padding-top: 20px;
    padding-left: 100px;
    padding-right: 100px;
}

.MuiCard-root{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.parentFlexSplit {
    display: flex;
    justify-content: center;
}